body{
  display: flex;
  flex-flow: column nowrap;
  background-color: #232841;
  overflow-x: hidden;
}

html {
  color: #fdfffc;
  font-family: Inter, sans-serif;
  font-size: 10px;
  word-spacing: .25rem;

  scrollbar-color: #fdfffc #232841;
  scrollbar-width: thin;
}

h1{
  font-size: 5rem;
  font-weight: 800;
}

h2{
  font-size: 4rem;
  font-weight: 750;
}

h3{
  font-size: 3.25rem;
  font-weight: 500;
}

h4{
  font-size: 2.5rem;
  font-weight: 500;
}

h5{
  font-size: 2.25rem;
  font-weight: 400;
}

p{
  font-size: 1.75rem;
  font-weight: 300;
}

a{
  outline: none;
  text-decoration: none;
  color: #fdfffc;
}


/*Header Styles*/
#header{
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  height: 10vh;
  width: 100%;
  flex: 0 10%;
  padding-top: 1.5rem;
  padding-bottom: .5rem;
  box-sizing: border-box
}

#header-logo-box{
  height: auto;
  width: 20%;
  max-height: 2px;
}

#header-logo{
  height: 36px;
  width: 289px;
}

#menu{
  display: flex;
  width: 30%;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

#menu-arrow-box{
  order: 1;
  flex: 1 1 0;
  padding: .5rem;
  box-sizing: border-box;
}

#menu-arrow{
  position: absolute;
  transition-property: fill;
  transition-duration: .5s;
  transition-timing-function: ease-in-out;
}

#menu-arrow:hover{
  fill: #ff8c61;
}

#menu-content{
  display: flex;
  order: 2;
  flex: 3 1 0;
  align-items: center;
  gap: 3rem;
  justify-content: flex-start;
  box-sizing: border-box;
}

.menu-text{
  font-size: 1.65rem;
  color: #ff8c61;
}



/*Home Page Container & Accents*/

#re-orient{
  display: grid;
  box-sizing: border-box;
  padding: 0 2rem;
  grid-template-rows: .5fr 1fr .5fr;
  grid-template-columns: 1fr auto 1fr;
}

#portfolio-email{
  position: sticky;
  bottom: 3.25rem;
  align-self: flex-end;
  grid-column: 3 / 4;
  grid-row: 1 / 5;
  justify-self: center;
}

#portfolio-links{
  margin: 10% 0;
  position: sticky;
  bottom: 3.25rem;
  align-self: flex-end;
  grid-column: 1 / 2;
  grid-row: 1 / 5;
  justify-self: center;
}





/*About Section Styles*/

.about{
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  white-space: normal;
  padding: 20%;
  box-sizing: border-box;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

#about-accent-top{
  width: auto;
  height: auto;
  align-self: start;
  box-sizing: border-box;
  margin-left: -5rem;
}

#about-accent-bottom{
  align-self: end;
  box-sizing: border-box;
  margin-top: 3rem;
  margin-right: -5rem;
}

.about-accent-styles{
  width: 100%;
  height: 100%;
}

#about-header-text-box{
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
}

#about-header-text-2{
  color: #ff8c61;
}

#about-header-text-3{
  transition-property: all;
  transition-duration: .75s;
  transition-timing-function: ease-in-out;
}

#about-header-text-3:hover{
  color: #ff8c61;
}




/*Work Section Styles*/

.work{
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  padding: 0 10%;
  box-sizing: border-box;
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

.work-title-container{
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.work-line{
  height: .75rem;
  width: 30%;
  border-radius: 5rem;
  background-color: #fdfffc;
}

#wl-left{
  order: 1;
}

#wl-right{
  order: 3;
}

#work-header{
  order: 2;
  display: flex;
  justify-content: center;
  padding: 0 2%;
}

#work-description-container{
  margin-bottom: 5%;
}

#work-description{
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 0 30%;
  box-sizing: border-box;
}

#work-grid{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 3fr 1fr 3fr 1fr;
}

.project-done{
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 2%;
}

.project-coming-soon{
  grid-column: 1 / 2;
  grid-row: 3 / 4;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  gap: 2%;
}

@keyframes fade-in-on-scroll {
  0%{
    transform: translateY(25%);
    opacity: 0;
  }

  70%{
    transform: translateY(0%);
    opacity: 1;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .project-done{
    animation: fade-in-on-scroll 2.25s 1;
  }

  .project-coming-soon{
    animation: fade-in-on-scroll 2.5s 1;
  }
}

.project{
  display: flex;
  flex-flow: column nowrap;
  color: #070707;
  box-sizing: border-box;
  justify-content: space-evenly;
  position: relative;
  height: calc(100%);
  width: calc(100%);
}

.project-folder{
  width: 100%;
  height: auto;
}

#folder-svg{
  width: 100%;
  height: auto;
}

.learn-more-text{
  font-size: 1.75rem;
  font-weight: 300;
}

.learn-more-icon{
  visibility: collapse;
}

.project-cage{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 10% 75% 15%;
  width: 100%;
  height: 100%;
  padding: 2.5% 2.75rem;
  position: absolute;
  justify-content: center;
  box-sizing: border-box;
}

.project-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

.project-info{
  display: flex;
  flex-flow: column nowrap;
  grid-column: 1 / 3;
  grid-row: 2 / 3;
}

.project-name{
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  justify-content: center;
}

.project-name-text{
  margin: 0;
  padding: calc(5%) 0;
}

.project-description{
  display: flex;
  flex: 2 1 auto;
  overflow: auto;
}

.project-details{
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-column: 1 / 3;
  grid-row: 3 / 4;
}

.project-tools{
  display: flex;
  align-items: center;
  gap: .75rem;
}

.project-tools-text{
  font-size: 1.5rem;
  color: dimgray;
}

.project-external-links{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.75rem;
}

#project-1{
  flex: 1 1 0;
}

#project-2{
  flex: 1 1 0;
}

#project-3{
  flex: 1 1 0;
}

#project-4{
  flex: 1 1 0;
}

#project-5{
  flex: 1 1 0;
}

#project-6{
  flex: 1 1 0;
}

.project-details{
  display: flex;
  flex-flow: row nowrap;
}

.project-tools-text{
  align-self: flex-start;
}

.coming-soon-outer-container{
  padding-top: 2rem;
  display: flex;
  flex-flow: column;
  justify-content: start;
  grid-column: 1 / 2;
  grid-row: 4 / 5;
  box-sizing: border-box;
  background-color: #232841;
}

.coming-soon-outer-container:focus-within{
  transition-property: all;
  transition-timing-function: ease-in-out;
  transition-duration: 5s;
  grid-row: 2 / 5;
  z-index: 100;
}

.coming-soon-inner-container{
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.coming-soon-line{
  height: 1rem;
  width: 100%;
  border-radius: 5rem;
  opacity: 50%;
  background-color: #969696;
}

.coming-soon-dot{
  width: 2.5rem;
  height: 1rem;
  border-radius: 5rem;
  opacity: 50%;
  background-color: #969696;
}

.coming-soon-anchor-box{
  order: 5;
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#cs-anchor-text{
  font-size: 2rem;
  font-weight: normal;
}

#cs-line-left{
  order: 1;
}

#cs-line-right{
  order: 7;
}

#cs-dot-left-1{
  order: 2;
}

#cs-dot-left-2{
  order: 3;
}

#cs-dot-right-1{
  order: 5;
}

#cs-dot-right-2{
  order: 6;
}






/*Skill & Contact Section Styles*/

.contact{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: .75fr 2.25fr .5fr;
  width: 100%;
  color: #fdfffc;
  box-sizing: border-box;
  grid-column: 2 / 3;
  grid-row: 3 / 4;
}

.skill-title-container{
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.skills-line{
  height: .75rem;
  width: 25%;
  border-radius: 5rem;
  background-color: #fdfffc;
}

#sl-left{
  order: 1;
}

#sl-right{
  order: 3;
}

.skill-header{
  padding-top: 5%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

.section-header-text{
  order: 2;
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 2rem;
}

.section-info{
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  display: flex;
  padding: 0 25%;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#skill-container{
  display: grid;
  padding: 5% 20%;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(4, 1fr);
  column-gap: 15%;
  row-gap: 15%;
  grid-column: 1 / 2;
  grid-row: 2 / 3;
  box-sizing: border-box;
}

.skills{
  display: flex;
  align-self: center;
  justify-self: center;
  justify-items: center;
  align-items: center;
  width: calc(50%);
  height: calc(50%);
}

.skills-icon-style-injector{
  fill: #666666;
  opacity: 75%;
  transition-property: all;
  transition-duration: 1.25s;
  transition-timing-function: ease-in-out;
  width: 100%;
  height: 100%;
}

.icons-with-white-accents{
  fill: #232841;
}

.skills-icon-style-injector:hover {
  fill: #ff8c61;
  opacity: 100%;

}

#skill-1{
  grid-column: 1 / 2;
  grid-row: 1 / 2;
}

#skill-2{
  grid-column: 2 / 3;
  grid-row: 1 / 2;
}

#skill-3{
  grid-column: 3 / 4;
  grid-row: 1 / 2;
}

#skill-4{
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

#skill-5{
  grid-column: 2 / 3;
  grid-row: 2 / 3;
}

#skill-6{
  grid-column: 3 / 4;
  grid-row: 2 / 3;
}

#skill-7{
  grid-column: 1 / 2;
  grid-row: 3 / 4;
}

#skill-8{
  grid-column: 2 / 3;
  grid-row: 3 / 4;
}

#skill-9{
  grid-column: 3 / 4;
  grid-row: 3 / 4;
}

#skill-10{
  grid-column: 1 / 2;
  grid-row: 4 / 5;
}

#skill-11{
  grid-column: 2 / 3;
  grid-row: 4 / 5;
}

#skill-12{
  grid-column: 3 / 4;
  grid-row: 4 / 5;
}

#skill-13{
  grid-column: 4 / 5;
  grid-row: 1 / 2;
}

#skill-14{
  grid-column: 4 / 5;
  grid-row: 2 / 3;
}

#skill-15{
  grid-column: 4 / 5;
  grid-row: 3 / 4;
}

#skill-16{
  grid-column: 4 / 5;
  grid-row: 4 / 5;
}


#button-box{
  display: flex;
  justify-content: center;
  grid-column: 1 / 2;
  grid-row: 3 / 4;
}

.button{
  background-color: #ff8c61;
  color: #232841;
  border: .2rem solid #ff8c61;
  border-radius: 5rem;
  font-size: 1.5rem;
  width: 15rem;
  padding: 1.25rem 1.75rem;
  margin: auto;
  text-align: center;
}

.button:hover{
  transition-timing-function: ease-in-out;
  transition-duration: 1s;
  transition-property: all;
  background-color: #fdfffc;
  border: none;
  color: dimgray;
}





/*Footer Styles*/

.footer{
  width: 100%;
  text-align: center;

}

#footer-text{
  font-weight: 900;
}






/*Breakpoints*/

/*XL Screens & TVs*/
@media only screen and (min-width: 1901px){

}

/*Small Screens & Laptops*/
@media only screen and (max-width: 1440px){
  #menu{
    margin-right: 5%;
  }
}

/*iPads & Tablets*/
@media only screen and (max-width: 1024px){

  #menu{
    flex-flow: column;
    width: 30%;
  }

   #menu-arrow{
     fill: #fdfffc;
   }

   #menu-arrow-box{
     margin-bottom: 2vh;
     align-self: end;
     margin-right: 5vw;
   }

   #menu-content{
     flex-flow: column;
     transform: translateX(100vw);
     opacity: 0;
     position: absolute;
     margin-top: 6.5vh;
   }

   .menu-item{
     display: flex;
     justify-content: start;
     align-items: center;
     border-radius: .75rem;
     height: 3.5rem;
     width: 45vw;
     background-color: #fdfffc;
     transform: translateX(12.5vw);
     padding-left: 12vw;
   }

   #about-menu-item {
     animation: menu-item-fade-in 1.25s 1;
   }

   #work-menu-item {
     animation: menu-item-fade-in 1.5s 1;
   }

   #contact-menu-item {
     animation: menu-item-fade-in 1.75s 1;
   }

   #resume-menu-item {
     animation: menu-item-fade-in 2s 1;
   }

   @keyframes menu-item-fade-in {
    0%{
      transform: translateX(100vw);
      opacity: 0;
    }
    100%{
      transform: translateX(12.5vw);
      opacity: 1;
    }
   }

   @keyframes open-menu {

   }

  .about{
    height: auto;
    width: 75%;
    padding: 20% 0;
    justify-self: center;
  }

  #re-orient{
    grid-template-rows: .5fr auto .5fr;
  }

  #work-grid{
    grid-template-rows: 3fr 3fr .5fr;
    gap: 2.5%;
  }

  .project-coming-soon{
    grid-row: 2 / 3;
  }

  #work-description{
    padding: 0 10vw;
  }

  .project-done{
    flex-flow: column;
  }

  .project-coming-soon{
    flex-flow: column;
  }

  .project{
    align-self: center;
    width: 60vw;
  }

  .project-icon{
    width: auto;
  }

  .learn-more-text{
    visibility: collapse;
  }

  .learn-more-icon{
    display: flex;
    align-items: center;
    visibility: visible;
    fill: #fdfffc;
    height: calc(50%);
    width: calc(40%);
  }

  #skill-container{
    padding: 5% 15%;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(8, 1fr);
    overflow: scroll;
  }

  .section-info{
    padding: 0 15%;
  }

  #skill-1{
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }

  #skill-2{
    grid-column: 2 / 3;
    grid-row: 1 / 2;
  }

  #skill-3{
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  #skill-4{
    grid-column: 2 / 3;
    grid-row: 2 / 3;
  }

  #skill-5{
    grid-column: 1 / 2;
    grid-row: 3 / 4;
  }

  #skill-6{
    grid-column: 2 / 3;
    grid-row: 3 / 4;
  }

  #skill-7{
    grid-column: 1 / 2;
    grid-row: 4 / 5;
  }

  #skill-8{
    grid-column: 2 / 3;
    grid-row: 4 / 5;
  }

  #skill-9{
    grid-column: 1 / 2;
    grid-row: 5 / 6;
  }

  #skill-10{
    grid-column: 2 / 3;
    grid-row: 5 / 6;
  }

  #skill-11{
    grid-column: 1 / 2;
    grid-row: 6 / 7;
  }

  #skill-12{
    grid-column: 2 / 3;
    grid-row: 6 / 7;
  }

  #skill-13{
    grid-column: 1 / 2;
    grid-row: 7 / 8;
  }

  #skill-14{
    grid-column: 2 / 3;
    grid-row: 7 / 8;
  }

  #skill-15{
    grid-column: 1 / 2;
    grid-row: 8 / 9;
  }

  #skill-16{
    grid-column: 2 / 3;
    grid-row: 8 / 9;
  }
}

/*Mobile*/
@media only screen and (max-width: 480px){
  .project-grid{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(6, 1fr);
    row-gap: 25px;
    padding: 2rem;
    box-sizing: border-box;
  }
}

