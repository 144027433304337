body {
  background-color: #232841;
  flex-flow: column;
  display: flex;
  overflow-x: hidden;
}

html {
  color: #fdfffc;
  word-spacing: .25rem;
  scrollbar-color: #fdfffc #232841;
  scrollbar-width: thin;
  font-family: Inter, sans-serif;
  font-size: 10px;
}

h1 {
  font-size: 5rem;
  font-weight: 800;
}

h2 {
  font-size: 4rem;
  font-weight: 750;
}

h3 {
  font-size: 3.25rem;
  font-weight: 500;
}

h4 {
  font-size: 2.5rem;
  font-weight: 500;
}

h5 {
  font-size: 2.25rem;
  font-weight: 400;
}

p {
  font-size: 1.75rem;
  font-weight: 300;
}

a {
  color: #fdfffc;
  outline: none;
  text-decoration: none;
}

#header {
  height: 10vh;
  width: 100%;
  box-sizing: border-box;
  flex-flow: row;
  flex: 0 10%;
  justify-content: space-between;
  padding-top: 1.5rem;
  padding-bottom: .5rem;
  display: flex;
}

#header-logo-box {
  height: auto;
  width: 20%;
  max-height: 2px;
}

#header-logo {
  height: 36px;
  width: 289px;
}

#menu {
  width: 30%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

#menu-arrow-box {
  box-sizing: border-box;
  flex: 1;
  order: 1;
  padding: .5rem;
}

#menu-arrow {
  transition-property: fill;
  transition-duration: .5s;
  transition-timing-function: ease-in-out;
  position: absolute;
}

#menu-arrow:hover {
  fill: #ff8c61;
}

#menu-content {
  box-sizing: border-box;
  flex: 3;
  order: 2;
  justify-content: flex-start;
  align-items: center;
  gap: 3rem;
  display: flex;
}

.menu-text {
  color: #ff8c61;
  font-size: 1.65rem;
}

#re-orient {
  box-sizing: border-box;
  grid-template-rows: .5fr 1fr .5fr;
  grid-template-columns: 1fr auto 1fr;
  padding: 0 2rem;
  display: grid;
}

#portfolio-email {
  grid-area: 1 / 3 / 5 / 4;
  place-self: flex-end center;
  position: sticky;
  bottom: 3.25rem;
}

#portfolio-links {
  grid-area: 1 / 1 / 5 / 2;
  place-self: flex-end center;
  margin: 10% 0;
  position: sticky;
  bottom: 3.25rem;
}

.about {
  height: auto;
  width: 100%;
  white-space: normal;
  box-sizing: border-box;
  flex-direction: column;
  grid-area: 1 / 2 / 2 / 3;
  padding: 20%;
  display: flex;
}

#about-accent-top {
  width: auto;
  height: auto;
  box-sizing: border-box;
  align-self: start;
  margin-left: -5rem;
}

#about-accent-bottom {
  box-sizing: border-box;
  align-self: end;
  margin-top: 3rem;
  margin-right: -5rem;
}

.about-accent-styles {
  width: 100%;
  height: 100%;
}

#about-header-text-box {
  flex-direction: row;
  gap: 1.25rem;
  display: flex;
}

#about-header-text-2 {
  color: #ff8c61;
}

#about-header-text-3 {
  transition-property: all;
  transition-duration: .75s;
  transition-timing-function: ease-in-out;
}

#about-header-text-3:hover {
  color: #ff8c61;
}

.work {
  height: auto;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  grid-area: 2 / 2 / 3 / 3;
  padding: 0 10%;
  display: flex;
}

.work-title-container {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.work-line {
  height: .75rem;
  width: 30%;
  background-color: #fdfffc;
  border-radius: 5rem;
}

#wl-left {
  order: 1;
}

#wl-right {
  order: 3;
}

#work-header {
  order: 2;
  justify-content: center;
  padding: 0 2%;
  display: flex;
}

#work-description-container {
  margin-bottom: 5%;
}

#work-description {
  text-align: center;
  box-sizing: border-box;
  justify-content: center;
  padding: 0 30%;
  display: flex;
}

#work-grid {
  grid-template-rows: 3fr 1fr 3fr 1fr;
  grid-template-columns: 1fr;
  display: grid;
}

.project-done {
  box-sizing: border-box;
  flex-direction: row;
  grid-area: 1 / 1 / 2 / 2;
  gap: 2%;
  display: flex;
}

.project-coming-soon {
  box-sizing: border-box;
  flex-direction: row;
  grid-area: 3 / 1 / 4 / 2;
  gap: 2%;
  display: flex;
}

@keyframes fade-in-on-scroll {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }

  70% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .project-done {
    animation: fade-in-on-scroll 2.25s;
  }

  .project-coming-soon {
    animation: fade-in-on-scroll 2.5s;
  }
}

.project {
  color: #070707;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  flex-flow: column;
  justify-content: space-evenly;
  display: flex;
  position: relative;
}

.project-folder, #folder-svg {
  width: 100%;
  height: auto;
}

.learn-more-text {
  font-size: 1.75rem;
  font-weight: 300;
}

.learn-more-icon {
  visibility: collapse;
}

.project-cage {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  grid-template-rows: 10% 75% 15%;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  padding: 2.5% 2.75rem;
  display: grid;
  position: absolute;
}

.project-icon {
  box-sizing: border-box;
  grid-area: 1 / 2 / 2 / 3;
  justify-content: center;
  align-items: center;
  display: flex;
}

.project-info {
  flex-flow: column;
  grid-area: 2 / 1 / 3 / 3;
  display: flex;
}

.project-name {
  flex-flow: column;
  flex: auto;
  justify-content: center;
  display: flex;
}

.project-name-text {
  margin: 0;
  padding: 5% 0;
}

.project-description {
  flex: 2 auto;
  display: flex;
  overflow: auto;
}

.project-details {
  grid-area: 3 / 1 / 4 / 3;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.project-tools {
  align-items: center;
  gap: .75rem;
  display: flex;
}

.project-tools-text {
  color: #696969;
  font-size: 1.5rem;
}

.project-external-links {
  justify-content: center;
  align-items: center;
  gap: 1.75rem;
  display: flex;
}

#project-1, #project-2, #project-3, #project-4, #project-5, #project-6 {
  flex: 1;
}

.project-details {
  flex-flow: row;
  display: flex;
}

.project-tools-text {
  align-self: flex-start;
}

.coming-soon-outer-container {
  box-sizing: border-box;
  background-color: #232841;
  flex-flow: column;
  grid-area: 4 / 1 / 5 / 2;
  justify-content: start;
  padding-top: 2rem;
  display: flex;
}

.coming-soon-outer-container:focus-within {
  z-index: 100;
  grid-row: 2 / 5;
  transition-property: all;
  transition-duration: 5s;
  transition-timing-function: ease-in-out;
}

.coming-soon-inner-container {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.coming-soon-line {
  height: 1rem;
  width: 100%;
  opacity: .5;
  background-color: #969696;
  border-radius: 5rem;
}

.coming-soon-dot {
  width: 2.5rem;
  height: 1rem;
  opacity: .5;
  background-color: #969696;
  border-radius: 5rem;
}

.coming-soon-anchor-box {
  width: 35%;
  order: 5;
  justify-content: center;
  align-items: center;
  display: flex;
}

#cs-anchor-text {
  font-size: 2rem;
  font-weight: normal;
}

#cs-line-left {
  order: 1;
}

#cs-line-right {
  order: 7;
}

#cs-dot-left-1 {
  order: 2;
}

#cs-dot-left-2 {
  order: 3;
}

#cs-dot-right-1 {
  order: 5;
}

#cs-dot-right-2 {
  order: 6;
}

.contact {
  width: 100%;
  color: #fdfffc;
  box-sizing: border-box;
  grid-area: 3 / 2 / 4 / 3;
  grid-template-rows: .75fr 2.25fr .5fr;
  grid-template-columns: 1fr;
  display: grid;
}

.skill-title-container {
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  display: flex;
}

.skills-line {
  height: .75rem;
  width: 25%;
  background-color: #fdfffc;
  border-radius: 5rem;
}

#sl-left {
  order: 1;
}

#sl-right {
  order: 3;
}

.skill-header {
  grid-area: 1 / 1 / 2 / 2;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr;
  padding-top: 5%;
  display: grid;
}

.section-header-text {
  text-align: center;
  order: 2;
  grid-area: 1 / 1 / 2 / 2;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
  display: flex;
}

.section-info {
  text-align: center;
  grid-area: 2 / 1 / 3 / 2;
  justify-content: center;
  align-items: center;
  padding: 0 25%;
  display: flex;
}

#skill-container {
  box-sizing: border-box;
  grid-area: 2 / 1 / 3 / 2;
  grid-template-rows: repeat(4, 1fr);
  grid-template-columns: repeat(4, 1fr);
  gap: 15%;
  padding: 5% 20%;
  display: grid;
}

.skills {
  width: 50%;
  height: 50%;
  place-self: center;
  place-items: center;
  display: flex;
}

.skills-icon-style-injector {
  fill: #666;
  opacity: .75;
  width: 100%;
  height: 100%;
  transition-property: all;
  transition-duration: 1.25s;
  transition-timing-function: ease-in-out;
}

.icons-with-white-accents {
  fill: #232841;
}

.skills-icon-style-injector:hover {
  fill: #ff8c61;
  opacity: 1;
}

#skill-1 {
  grid-area: 1 / 1 / 2 / 2;
}

#skill-2 {
  grid-area: 1 / 2 / 2 / 3;
}

#skill-3 {
  grid-area: 1 / 3 / 2 / 4;
}

#skill-4 {
  grid-area: 2 / 1 / 3 / 2;
}

#skill-5 {
  grid-area: 2 / 2 / 3 / 3;
}

#skill-6 {
  grid-area: 2 / 3 / 3 / 4;
}

#skill-7 {
  grid-area: 3 / 1 / 4 / 2;
}

#skill-8 {
  grid-area: 3 / 2 / 4 / 3;
}

#skill-9 {
  grid-area: 3 / 3 / 4 / 4;
}

#skill-10 {
  grid-area: 4 / 1 / 5 / 2;
}

#skill-11 {
  grid-area: 4 / 2 / 5 / 3;
}

#skill-12 {
  grid-area: 4 / 3 / 5 / 4;
}

#skill-13 {
  grid-area: 1 / 4 / 2 / 5;
}

#skill-14 {
  grid-area: 2 / 4 / 3 / 5;
}

#skill-15 {
  grid-area: 3 / 4 / 4 / 5;
}

#skill-16 {
  grid-area: 4 / 4 / 5 / 5;
}

#button-box {
  grid-area: 3 / 1 / 4 / 2;
  justify-content: center;
  display: flex;
}

.button {
  color: #232841;
  width: 15rem;
  text-align: center;
  background-color: #ff8c61;
  border: .2rem solid #ff8c61;
  border-radius: 5rem;
  margin: auto;
  padding: 1.25rem 1.75rem;
  font-size: 1.5rem;
}

.button:hover {
  color: #696969;
  background-color: #fdfffc;
  border: none;
  transition-property: all;
  transition-duration: 1s;
  transition-timing-function: ease-in-out;
}

.footer {
  width: 100%;
  text-align: center;
}

#footer-text {
  font-weight: 900;
}

@media only screen and (max-width: 1440px) {
  #menu {
    margin-right: 5%;
  }
}

@media only screen and (max-width: 1024px) {
  #menu {
    width: 30%;
    flex-flow: column;
  }

  #menu-arrow {
    fill: #fdfffc;
  }

  #menu-arrow-box {
    align-self: end;
    margin-bottom: 2vh;
    margin-right: 5vw;
  }

  #menu-content {
    opacity: 0;
    flex-flow: column;
    margin-top: 6.5vh;
    position: absolute;
    transform: translateX(100vw);
  }

  .menu-item {
    height: 3.5rem;
    width: 45vw;
    background-color: #fdfffc;
    border-radius: .75rem;
    justify-content: start;
    align-items: center;
    padding-left: 12vw;
    display: flex;
    transform: translateX(12.5vw);
  }

  #about-menu-item {
    animation: menu-item-fade-in 1.25s;
  }

  #work-menu-item {
    animation: menu-item-fade-in 1.5s;
  }

  #contact-menu-item {
    animation: menu-item-fade-in 1.75s;
  }

  #resume-menu-item {
    animation: menu-item-fade-in 2s;
  }

  @keyframes menu-item-fade-in {
    0% {
      opacity: 0;
      transform: translateX(100vw);
    }

    100% {
      opacity: 1;
      transform: translateX(12.5vw);
    }
  }

  @keyframes open-menu {
  }

  .about {
    height: auto;
    width: 75%;
    justify-self: center;
    padding: 20% 0;
  }

  #re-orient {
    grid-template-rows: .5fr auto .5fr;
  }

  #work-grid {
    grid-template-rows: 3fr 3fr .5fr;
    gap: 2.5%;
  }

  .project-coming-soon {
    grid-row: 2 / 3;
  }

  #work-description {
    padding: 0 10vw;
  }

  .project-done, .project-coming-soon {
    flex-flow: column;
  }

  .project {
    width: 60vw;
    align-self: center;
  }

  .project-icon {
    width: auto;
  }

  .learn-more-text {
    visibility: collapse;
  }

  .learn-more-icon {
    visibility: visible;
    fill: #fdfffc;
    height: 50%;
    width: 40%;
    align-items: center;
    display: flex;
  }

  #skill-container {
    grid-template-rows: repeat(8, 1fr);
    grid-template-columns: repeat(2, 1fr);
    padding: 5% 15%;
    overflow: scroll;
  }

  .section-info {
    padding: 0 15%;
  }

  #skill-1 {
    grid-area: 1 / 1 / 2 / 2;
  }

  #skill-2 {
    grid-area: 1 / 2 / 2 / 3;
  }

  #skill-3 {
    grid-area: 2 / 1 / 3 / 2;
  }

  #skill-4 {
    grid-area: 2 / 2 / 3 / 3;
  }

  #skill-5 {
    grid-area: 3 / 1 / 4 / 2;
  }

  #skill-6 {
    grid-area: 3 / 2 / 4 / 3;
  }

  #skill-7 {
    grid-area: 4 / 1 / 5 / 2;
  }

  #skill-8 {
    grid-area: 4 / 2 / 5 / 3;
  }

  #skill-9 {
    grid-area: 5 / 1 / 6 / 2;
  }

  #skill-10 {
    grid-area: 5 / 2 / 6 / 3;
  }

  #skill-11 {
    grid-area: 6 / 1 / 7 / 2;
  }

  #skill-12 {
    grid-area: 6 / 2 / 7 / 3;
  }

  #skill-13 {
    grid-area: 7 / 1 / 8 / 2;
  }

  #skill-14 {
    grid-area: 7 / 2 / 8 / 3;
  }

  #skill-15 {
    grid-area: 8 / 1 / 9 / 2;
  }

  #skill-16 {
    grid-area: 8 / 2 / 9 / 3;
  }
}

@media only screen and (max-width: 480px) {
  .project-grid {
    box-sizing: border-box;
    grid-template-rows: repeat(6, 1fr);
    grid-template-columns: repeat(1, 1fr);
    row-gap: 25px;
    padding: 2rem;
    display: grid;
  }
}

/*# sourceMappingURL=index.e8689971.css.map */
